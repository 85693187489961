<template>
    <b-container>
        <b-form class="bg-sukudai-green px-2 py-3" @submit="onSubmit">
            <b-form-row class="text-white">
                <b-col cols="12">
                    <b-row cols="12" class="p-2 mb-2 mx-1 justify-content-center bg-sukudai-green">
                        <b-col cols="2" class="w-100"
                               style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
                        <b-col cols="6">
                            <h2 class="text-white col-12 text-center font-weight-bolder">表單諮詢</h2>
                        </b-col>
                        <b-col cols="2" class="w-100"
                               style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
                    </b-row>
                </b-col>
                <b-col cols="12">
                    <h3 class="font-weight-bold text-center">基本資料</h3>
                </b-col>
                <b-col cols="12">
                    <b-row class="border border-white m-1 px-1 py-2">
                        <b-col cols="12" md="6" lg="4">
                            <b-form-group id="input-group-name" label="姓名*">
                                <b-form-input v-model="form.name" required
                                              id="input-name"/>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="6" lg="4">
                            <b-form-group id="input-group-id_number" label="身分證字號*">
                                <b-form-input v-model="form.id_number" required
                                              id="input-id_number">
                                </b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="6" lg="4">
                            <b-form-group id="input-group-mobile" label="行動電話*">
                                <b-form-input v-model="form.mobile" type="tel" required
                                              id="input-mobile">
                                </b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="6" lg="4">
                            <b-form-group id="input-group-age" label="年齡">
                                <b-form-input v-model="form.age" type="number"
                                              id="input-age" min="20" max="99">
                                </b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="6" lg="4">
                            <b-form-group id="input-group-birthday" label="生日">
                                <b-form-input v-model="form.birthday" type="date"
                                              id="input-birthday">
                                </b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="6" lg="4">
                            <b-form-group id="input-group-tel" label="公司/住家電話">
                                <b-form-input v-model="form.tel" type="tel"
                                              id="input-tel">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="12" class="mt-3">
                    <h3 class="font-weight-bold text-center">工作資料</h3>
                </b-col>
                <b-col cols="12">
                    <b-row class="border border-white m-1 px-1 py-2">
                        <b-col cols="4">
                            <b-form-group id="input-group-job" label="工作類型*">
                                <b-form-radio-group
                                    id="radio-job" v-model="form.job" required
                                    :options="options.job"/>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-income_type" label="收入方式">
                                <b-form-radio-group id="radio-income-type" v-model="form.income_type"
                                                    :options="options.income_type" required/>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-income_amount" label="月薪(元)">
                                <b-form-input v-model="form.income_amount" type="number"
                                              id="input-income_amount">
                                </b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-insured" label="投保單位">
                                <b-form-radio-group v-model="form.insured" id="input-insured"
                                                    :options="options.insured_unit"/>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-income_proof" label="薪轉">
                                <b-form-radio-group v-model="form.income_proof" id="input-income_proof"
                                                    :options="options.have">
                                </b-form-radio-group>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-working_years" label="工作年資(年)">
                                <b-form-input v-model="form.working_years" type="number" placeholder="工作年資"
                                              min="0" max="50"
                                              id="input-working_years">
                                </b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-withholding" label="扣憑單">
                                <b-form-radio-group v-model="form.withholding" id="input-withholding"
                                                    :options="options.have">
                                </b-form-radio-group>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-withholding" label="扣憑單金額(元)">
                                <b-form-input v-model="form.withholding_amount" type="number" placeholder="扣憑單金額"
                                              min="0" max="1000000"
                                              id="input-income_proof">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="12" class="mt-3">
                    <h3 class="font-weight-bold text-center">負債情況</h3>
                </b-col>
                <b-col cols="12">
                    <!-- 信用卡 -->
                    <b-row class="border border-white m-1 px-1 py-2">
                        <b-col cols="12" lg="1" class="text-center font-weight-bold font-md">
                            信用卡
                        </b-col>
                        <b-col cols="12" lg="11" class="">
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group id="input-group-have_credit_card" label="擁有信用卡">
                                        <b-form-radio-group v-model="show.have_credit_card"
                                                            id="input-have_credit_card"
                                                            :options="options.have"
                                        />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" v-if="show.have_credit_card">
                                    <b-row>
                                        <b-col cols="12" lg="4">
                                            <b-form-group
                                                id="input-group-credit_bank"
                                                :label="`信用卡銀行${show.have_credit_card ? '*' : ''}`">

                                                <b-form-input
                                                    v-model="form.credit_bank"
                                                    id="input-credit_bank"
                                                    :required="show.have_credit_card ? 'required' : ''"/>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-credit_amount"
                                                          :label="`信用卡額度(萬)${show.have_credit_card ? '*' : ''}`">
                                                <b-form-input v-model="form.credit_amount"
                                                              type="number" min="1" max="100"
                                                              id="input-credit_amount"
                                                              :required="show.have_credit_card ? 'required' : ''">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-credit_use_years"
                                                          :label="`信用卡使用時間(年)${show.have_credit_card ? '*' : ''}`">
                                                <b-form-input v-model="form.credit_use_years"
                                                              type="number" min="0" max="99"
                                                              :required="show.have_credit_card ? 'required' : ''"
                                                              id="input-credit_use_years">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-credit_type"
                                                          :label="`卡別${show.have_credit_card ? '*' : ''}`">
                                                <b-form-input v-model="form.credit_type"
                                                              :required="show.have_credit_card ? 'required' : ''"
                                                              id="input-credit_type">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-have_revolving"
                                                          :label="`是否循環${show.have_credit_card ? '*' : ''}`">
                                                <b-form-radio-group v-model="form.have_revolving"
                                                                    :required="show.have_credit_card ? 'required' : ''"
                                                                    id="input-have_revolving"
                                                                    :options="options.have"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-revolving_amount"
                                                          :label="`循環額度(萬)${show.have_credit_card ? '*' : ''}`">
                                                <b-form-input v-model="form.revolving_amount"
                                                              :required="show.have_credit_card ? 'required' : ''"
                                                              type="number" min="0" max="100"
                                                              id="input-revolving_amount">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-credit_pay_for_delay"
                                                          :label="`是否繳款正常${show.have_credit_card ? '*' : ''}`">
                                                <b-form-radio-group v-model="form.credit_pay_for_delay"
                                                                    :required="show.have_credit_card ? 'required' : ''"
                                                                    id="input-credit_pay_for_delay"
                                                                    :options="options.have"
                                                                    name="credit_pay_for_delay"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col cols="12" lg="1" class="text-center font-weight-bold font-md">
                            現金卡
                        </b-col>
                        <b-col cols="12" lg="11" class="">
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group id="input-group-have_cash_card" label="擁有現金卡">
                                        <b-form-radio-group v-model="show.have_cash_card"
                                                            id="input-have_cash_card"
                                                            :options="options.have"
                                        >
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" v-if="show.have_cash_card">
                                    <b-row>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-cash_amount"
                                                          :label="`現金卡額度(萬)${show.have_cash_card ? '*' : ''}`">
                                                <b-form-input v-model="form.cash_amount"
                                                              type="number" min="1" max="1000"
                                                              :required="show.have_cash_card ? 'required' : ''"
                                                              id="input-cash_amount"/>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-cash_pay_for_delay"
                                                          :label="`是否繳款正常${show.have_cash_card ? '*' : ''}`">
                                                <b-form-radio-group v-model="form.cash_pay_for_delay"
                                                                    :required="show.have_cash_card ? 'required' : ''"
                                                                    id="input-cash_pay_for_delay"
                                                                    :options="options.have"
                                                                    name="cash_pay_for_delay"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col cols="12" lg="1" class="text-center font-weight-bold font-md">
                            信用貸款
                        </b-col>
                        <b-col cols="12" lg="11" class="">
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group id="input-group-have_loan" label="是否有個人信貸">
                                        <b-form-radio-group v-model="show.have_loan"
                                                            id="input-have_loan"
                                                            :options="options.have"
                                        >
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" v-if="show.have_loan">
                                    <b-row>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-loan_bank"
                                                          :label="`信貸銀行${show.have_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.loan_bank"
                                                              :required="show.have_loan ? 'required' : ''"
                                                              id="input-loan_bank">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-loan_amount"
                                                          :label="`信貸申請額度(萬)${show.have_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.loan_amount"
                                                              :required="show.have_loan ? 'required' : ''"
                                                              type="number" min="1" max="9999"
                                                              id="input-loan_amount">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-loan_remain"
                                                          :label="`信貸剩餘額度(萬)${show.have_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.loan_remain"
                                                              :required="show.have_loan ? 'required' : ''"
                                                              type="number" min="1" max="9999"
                                                              id="input-loan_remain">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-loan_total_month"
                                                          :label="`信貸申請期數${show.have_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.loan_total_month"
                                                              :required="show.have_loan ? 'required' : ''"
                                                              type="number" min="1" max="360"
                                                              id="input-loan_total_month">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-load_each_month_paid"
                                                          :label="`信貸月付金${show.have_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.load_each_month_paid"
                                                              :required="show.have_loan ? 'required' : ''"
                                                              type="number" min="1000" max="1000000"
                                                              id="input-load_each_month_paid">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col cols="12" lg="1" class="text-center font-weight-bold font-md">
                            房屋貸款
                        </b-col>
                        <b-col cols="12" lg="11" class="">
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group id="input-group-have_home_loan" label="是否有房貸">
                                        <b-form-radio-group v-model="show.have_home_loan"
                                                            id="input-have_home_loan"
                                                            :options="options.have"
                                        >
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" v-if="show.have_home_loan">
                                    <b-row>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-home_loan_bank"
                                                          :label="`房貸銀行${show.have_home_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.home_loan_bank"
                                                              :required="show.have_home_loan ? 'required' : ''"
                                                              id="input-home_loan_bank">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-home_loan_amount"
                                                          :label="`房貸申請額度(萬)${show.have_home_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.home_loan_amount"
                                                              :required="show.have_home_loan ? 'required' : ''"
                                                              type="number" min="1" max="10000"
                                                              id="input-home_loan_amount">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-home_loan_paid_month"
                                                          :label="`房貸已繳期數${show.have_home_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.home_loan_paid_month"
                                                              :required="show.have_home_loan ? 'required' : ''"
                                                              type="number" min="1" max="360"
                                                              id="input-home_loan_paid_month">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-home_loan_each_month_paid"
                                                          :label="`房貸月付金額${show.have_home_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.home_loan_each_month_paid"
                                                              :required="show.have_home_loan ? 'required' : ''"
                                                              type="number" min="1000" max="1000000"
                                                              id="input-home_loan_each_month_paid">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col cols="12" lg="1" class="text-center font-weight-bold font-md">
                            企業貸款
                        </b-col>
                        <b-col cols="12" lg="11" class="">
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group id="input-group-have_enterprise_loan" label="是否有企業貸款">
                                        <b-form-radio-group v-model="show.have_enterprise_loan"
                                                            id="input-have_enterprise_loan"
                                                            :options="options.have"
                                        >
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" v-if="show.have_enterprise_loan">
                                    <b-row>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-enterprise_loan_bank"
                                                          :label="`企業貸款申請銀行${show.have_enterprise_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.enterprise_loan_bank"
                                                              :required="show.have_enterprise_loan ? 'required' : ''"

                                                              id="input-enterprise_loan_bank">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-enterprise_loan_amount"
                                                          :label="`企業貸款申請額度(萬)${show.have_enterprise_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.enterprise_loan_amount"
                                                              :required="show.have_enterprise_loan ? 'required' : ''"
                                                              type="number" min="1" max="10000"
                                                              id="input-enterprise_loan_amount">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-enterprise_loan_remain"
                                                          :label="`企業貸款剩餘額度(萬)${show.have_enterprise_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.enterprise_loan_remain"
                                                              :required="show.have_enterprise_loan ? 'required' : ''"
                                                              type="number" min="1" max="10000"
                                                              id="input-enterprise_loan_remain">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-enterprise_loan_total_month"
                                                          :label="`企業貸款申請期數${show.have_enterprise_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.enterprise_loan_total_month"
                                                              :required="show.have_enterprise_loan ? 'required' : ''"
                                                              type="number" min="1" max="360"
                                                              id="input-enterprise_loan_total_month">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-enterprise_loan_each_month_paid"
                                                          :label="`企業貸款已繳期數${show.have_enterprise_loan ? '*' : ''}`">
                                                <b-form-input v-model="form.enterprise_loan_each_month_paid"
                                                              :required="show.have_enterprise_loan ? 'required' : ''"
                                                              type="number" min="1" max="360"
                                                              id="input-enterprise_loan_each_month_paid">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col cols="12" lg="1" class="text-center font-weight-bold font-md">
                            汽車貸款
                        </b-col>
                        <b-col cols="12" lg="11" class="">
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group id="input-group-have_car" label="是否有車貸">
                                        <b-form-radio-group v-model="show.have_car"
                                                            id="input-have_car"
                                                            :options="options.have"
                                        >
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" v-if="show.have_car">
                                    <b-row>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-license_plate_number"
                                                          :label="`車牌號碼${show.have_car ? '*' : ''}`">
                                                <b-form-input v-model="form.license_plate_number"
                                                              :required="show.have_car ? 'required' : ''"
                                                              id="input-license_plate_number">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-car_loan_bank"
                                                          :label="`車貸申請銀行${show.have_car ? '*' : ''}`">
                                                <b-form-input v-model="form.car_loan_bank"
                                                              :required="show.have_car ? 'required' : ''"
                                                              id="input-car_loan_bank">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-car_loan_amount"
                                                          :label="`車貸申請額度(萬)${show.have_car ? '*' : ''}`">
                                                <b-form-input v-model="form.car_loan_amount"
                                                              :required="show.have_car ? 'required' : ''"
                                                              type="number" min="1" max="10000"
                                                              id="input-car_loan_amount">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-car_loan_remain"
                                                          :label="`車貸剩餘額度(萬)${show.have_car ? '*' : ''}`">
                                                <b-form-input v-model="form.car_loan_remain"
                                                              :required="show.have_car ? 'required' : ''"
                                                              type="number" min="1" max="10000"
                                                              id="input-car_loan_remain">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-car_loan_total_month"
                                                          :label="`車貸申請期數${show.have_car ? '*' : ''}`">
                                                <b-form-input v-model="form.car_loan_total_month"
                                                              :required="show.have_car ? 'required' : ''"
                                                              type="number" min="1" max="360"
                                                              id="input-car_loan_total_month">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-car_loan_remain_month"
                                                          :label="`車貸剩餘期數${show.have_car ? '*' : ''}`">
                                                <b-form-input v-model="form.car_loan_remain_month"
                                                              :required="show.have_car ? 'required' : ''"
                                                              type="number" min="1" max="360"
                                                              id="input-car_loan_remain_month">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <b-form-group id="input-group-car_loan_each_month_paid"
                                                          :label="`車貸月付金${show.have_car ? '*' : ''}`">
                                                <b-form-input v-model="form.car_loan_each_month_paid"
                                                              :required="show.have_car ? 'required' : ''"
                                                              type="number" min="1000" max="1000000"
                                                              id="input-car_loan_each_month_paid">
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col cols="12" lg="12">
                            <b-form-group id="input-group-other_load" label="其他貸款">
                                <b-form-input v-model="form.other_load"
                                              id="input-other_load">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="12" class="mt-3">
                    <h3 class="font-weight-bold text-center">服務單位</h3>
                </b-col>
                <b-col cols="12">
                    <b-row class="border border-white m-1 px-1 py-2">
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-company" label="公司名稱">
                                <b-form-input v-model="form.company"
                                              id="input-company">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-company_type" label="公司類型">
                                <b-form-input v-model="form.company_type"
                                              id="input-company_type">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-company_id" label="公司統一編號">
                                <b-form-input v-model="form.company_id"
                                              id="input-company_id">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-company_tel" label="公司電話">
                                <b-form-input v-model="form.company_tel"
                                              id="input-company_tel">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-company_tel_ext" label="公司分機">
                                <b-form-input v-model="form.company_tel_ext"
                                              id="input-company_tel_ext">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-company_address" label="公司地址">
                                <b-form-input v-model="form.company_address"
                                              id="input-company_address">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-title" label="職稱">
                                <b-form-input v-model="form.title"
                                              id="input-title">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-experience" label="年資">
                                <b-form-input v-model="form.experience"
                                              id="input-experience">
                                </b-form-input>
                            </b-form-group>
                        </b-col>

                    </b-row>
                </b-col>

                <b-col cols="12" class="mt-3">
                    <h3 class="font-weight-bold text-center">申請資料</h3>
                </b-col>
                <b-col cols="12">
                    <b-row class="border border-white m-1 px-1 py-2">
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-trade_amount" label="買賣金額(萬)*">
                                <b-form-input v-model="form.trade_amount" required
                                              type="number" min="1" :max="10000"
                                              id="input-trade_amount">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-apply_amount" label="欲申貸金額(萬)*">
                                <b-form-input v-model="form.apply_amount" required
                                              type="number" min="1" :max="10000"
                                              id="input-apply_amount">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-apply_total_amount" label="欲申貸期數*">
                                <b-form-input v-model="form.apply_total_amount" required
                                              type="number" min="1" max="360"
                                              id="input-apply_total_amount">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-apply_each_month_paid" label="希望月付金*">
                                <b-form-input v-model="form.apply_each_month_paid" required
                                              type="number" min="1000"
                                              id="input-apply_each_month_paid">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-education" label="學歷*">
                                <b-form-radio-group v-model="form.education"
                                                    id="input-have_education"
                                                    :options="options.education"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-email" label="電子信箱">
                                <b-form-input v-model="form.email" type="email"
                                              id="input-email">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group id="input-group-address_of_tel" label="通訊地址*">
                                <b-form-input v-model="form.address_of_tel" required
                                              id="input-address_of_tel">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="12" class="text-center mt-2">
                    <b-button type="submit" class="font-weight-bold font-lg text-white px-3 px-lg-5" variant="warning">
                        送出
                    </b-button>
                </b-col>
            </b-form-row>
        </b-form>
    </b-container>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: '',
                id_number: '',
                mobile: '',
                age: '',
                birthday: '',
                tel: '',
                job: '',
                income_type: '',
                income_amount: '',
                insured: '',
                income_proof: '',
                working_years: '',
                withholding: '',
                withholding_amount: '',
                have_credit_card: '',
                credit_bank: '',
                credit_amount: '',
                credit_use_years: '',
                credit_type: '',
                have_revolving: '',
                revolving_amount: '',
                credit_pay_for_delay: '',
                cash_card: '',
                cash_amount: '',
                cash_pay_for_delay: '',
                have_loan: '',
                loan_bank: '',
                loan_amount: '',
                loan_remain: '',
                loan_total_month: '',
                load_each_month_paid: '',
                have_home_loan: '',
                home_loan_bank: '',
                home_loan_amount: '',
                home_loan_paid_month: '',
                home_loan_each_month_paid: '',
                have_enterprise_loan: '',
                enterprise_loan_bank: '',
                enterprise_loan_amount: '',
                enterprise_loan_remain: '',
                enterprise_loan_total_month: '',
                enterprise_loan_each_month_paid: '',
                have_car: '',
                license_plate_number: '',
                car_loan_bank: '',
                car_loan_amount: '',
                car_loan_remain: '',
                car_loan_total_month: '',
                car_loan_remain_month: '',
                car_loan_each_month_paid: '',
                other_load: '',
                company: '',
                company_type: '',
                company_id: '',
                company_tel: '',
                company_tel_ext: '',
                company_address: '',
                title: '',
                experience: '',
                trade_amount: '',
                apply_amount: '',
                apply_total_amount: '',
                apply_each_month_paid: '',
                education: '',
                email: '',
                address_of_tel: '',
            },
            show: {
                have_credit_card: false, // 信用卡
                have_cash_card: false, // 現金卡
                have_loan: false, // 個人信貸
                have_home_loan: false, // 房貸
                have_enterprise_loan: false, // 企業貸款
                have_car: false, // 車貸
            },
            options: {
                have: [
                    {value: true, 'text': '有'},
                    {value: false, 'text': '沒有'},
                ],
                job: [
                    {value: '上班族', 'text': '上班族'},
                    {value: '公務員', 'text': '公務員'},
                    {value: '職業軍人', 'text': '職業軍人'},
                    {value: '自營商', 'text': '自營商'},
                    {value: '自由業', 'text': '自由業'},
                    {value: '家管', 'text': '家管'},
                    {value: '其他', 'text': '其他'},
                ],
                income_type: [
                    {value: '領現金', 'text': '領現金'},
                    {value: '銀行轉帳', 'text': '銀行轉帳'},
                ],
                insured_unit: [
                    {value: '公司', 'text': '公司'},
                    {value: '公會', 'text': '公會'},
                    {value: '無勞健保', 'text': '無勞健保'},
                ],
                company_type: [
                    {value: '自營', 'text': '自營'},
                    {value: '受雇', 'text': '受雇'},
                ],
                education: [
                    {value: '研究所', 'text': '研究所'},
                    {value: '大專院校', 'text': '大專院校'},
                    {value: '高中職', 'text': '高中職'},
                    {value: '國中', 'text': '國中'},
                    {value: '國小', 'text': '國小'},
                    {value: '其他', 'text': '其他'},
                ],
            },
        }
    },
    methods: {
        onSubmit(evt) {
            evt.preventDefault();
            let total_column = {};
            Object.assign(total_column, this.form, this.show);

            this.axios({
                method: 'POST',
                url: 'https://script.google.com/macros/s/AKfycbzsxhNvWhTKqniMFyy5mlS7RToVpnfCqECeAOuy-SSu-sAca8YJErD8hmYyMZ2yfo_9/exec',
                data: JSON.stringify(total_column),
                mimeType: 'application/JSON'
            }).then(() => {
                this.$router.push({'name': 'Thanks'});
            }).catch(err => {
                console.log(err);
                alert('Got error!');
            })

        },
        onReset(evt) {
            evt.preventDefault();
        },

        done() {
            this.$router.push({'name': 'Thanks'});
        },
    }
}
</script>